import React from 'react'
import BgBlock from '../components/bg-block'
import Layout from '../components/layout/layout'
import MintSolana from '../components/mint-solana'
import '../styles/main.scss'

// markup
const IndexPage = () => (
  <Layout
    isHomePage
    metaTitle="22 percent - mint Solana"
    metaDescription="Mint Solana"
  >
    <BgBlock
      color="var(--bg-block-color-4)"
      backgroundColor="var(--bg-block-bg-color-4)"
    >
      <MintSolana />
    </BgBlock>
  </Layout>
)

export default IndexPage
