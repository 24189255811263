/* eslint-disable */
import React from 'react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import confetti from 'canvas-confetti'
import * as anchor from '@project-serum/anchor'
import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js'
import { useAnchorWallet } from '@solana/wallet-adapter-react'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { GatewayProvider } from '@civic/solana-gateway-react'
import Countdown from 'react-countdown'
import { Snackbar, Paper, LinearProgress, Chip } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { toDate, getAtaForMint } from './utils'
import { MintButton } from './MintButton'
import gif from '../../images/loading.gif'
import {
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  CANDY_MACHINE_PROGRAM,
} from './candy-machine'
import './index.scss'
import {
  SOLANA_NETWORK,
  SPL_TOKEN_TO_MINT_DECIMALS,
  SPL_TOKEN_TO_MINT_NAME,
} from '../../../config'

const cluster = SOLANA_NETWORK
const decimals = SPL_TOKEN_TO_MINT_DECIMALS.length
  ? +SPL_TOKEN_TO_MINT_DECIMALS
  : 9
const splTokenName = SPL_TOKEN_TO_MINT_NAME.length
  ? SPL_TOKEN_TO_MINT_NAME
  : 'TOKEN'

const WalletContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`

const WalletAmount = styled.div`
  color: black;
  width: auto;
  padding: 5px 5px 5px 16px;
  min-width: 48px;
  min-height: auto;
  border-radius: 22px;
  background-color: var(--main-text-color);
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  line-height: 1.75;
  text-transform: uppercase;
  border: 0;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: flex-start;
  gap: 10px;
`

const Wallet = styled.ul`
  flex: 0 0 auto;
  margin: 0;
  padding: 0;
`

const ConnectButton = styled(WalletMultiButton)`
  border-radius: 18px !important;
  padding: 6px 16px;
  background-color: #4e44ce;
  margin: 0 auto;
`

const NFT = styled(Paper)`
  min-width: 400px;
  padding: 5px 20px 20px 20px;
  flex: 1 1 auto;
  background-color: var(--card-background-color) !important;
`

const Card = styled(Paper)`
  display: inline-block;
  background-color: var(--card-background-lighter-color) !important;
  margin: 5px;
  padding: 24px;
`

const MintButtonContainer = styled.div`
  button.MuiButton-contained:not(.MuiButton-containedPrimary).Mui-disabled {
    color: #464646;
  }

  button.MuiButton-contained:not(.MuiButton-containedPrimary):hover,
  button.MuiButton-contained:not(.MuiButton-containedPrimary):focus {
    -webkit-animation: pulse 1s;
    animation: pulse 1s;
    box-shadow: 0 0 0 2em rgba(255, 255, 255, 0);
  }

  @-webkit-keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #ef8f6e;
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #ef8f6e;
    }
  }
`

const SolExplorerLink = styled.a`
  color: var(--title-text-color);
  border-bottom: 1px solid var(--title-text-color);
  font-weight: bold;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  outline: none;
  text-decoration: none;
  text-size-adjust: 100%;

  :hover {
    border-bottom: 2px solid var(--title-text-color);
  }
`

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 4%;
  margin-left: 4%;
  text-align: center;
  justify-content: center;
`

const MintContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  flex-wrap: wrap;
  gap: 20px;
`

const Price = styled(Chip)`
  position: absolute;
  margin: 5px;
  font-weight: bold;
  font-size: 1em !important;
`

const Image = styled.img`
  height: 400px;
  width: auto;
  border-radius: 7px;
  box-shadow: 5px 5px 40px 5px rgba(0, 0, 0, 0.5);
`

const BorderLinearProgress = styled(LinearProgress)`
  margin: 20px 0;
  height: 10px !important;
  border-radius: 30px;
  border: 2px solid white;
  box-shadow: 5px 5px 40px 5px rgba(0, 0, 0, 0.5);
  background-color: var(--main-text-color) !important;

  > div.MuiLinearProgress-barColorPrimary {
    background-color: var(--title-text-color) !important;
  }

  > div.MuiLinearProgress-bar1Determinate {
    border-radius: 30px !important;
    background-image: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.01),
      rgba(255, 255, 255, 0.5)
    );
  }
`

const ShimmerTitle = styled.h1`
  margin: 50px auto;
  text-transform: uppercase;
  animation: glow 2s ease-in-out infinite alternate;
  color: var(--main-text-color);
  @keyframes glow {
    from {
      text-shadow: 0 0 20px var(--main-text-color);
    }
    to {
      text-shadow: 0 0 30px var(--title-text-color),
        0 0 10px var(--title-text-color);
    }
  }
`

const Home = (props) => {
  const [balance, setBalance] = useState()
  const [isMinting, setIsMinting] = useState(false) // true when user got to press MINT
  const [isActive, setIsActive] = useState(false) // true when countdown completes or whitelisted
  const [solanaExplorerLink, setSolanaExplorerLink] = useState('')
  const [itemsAvailable, setItemsAvailable] = useState(0)
  const [itemsRedeemed, setItemsRedeemed] = useState(0)
  const [itemsRemaining, setItemsRemaining] = useState(0)
  const [isSoldOut, setIsSoldOut] = useState(false)
  const [payWithSplToken, setPayWithSplToken] = useState(false)
  const [price, setPrice] = useState(0)
  const [priceLabel, setPriceLabel] = useState('SOL')
  const [whitelistPrice, setWhitelistPrice] = useState(0)
  const [whitelistEnabled, setWhitelistEnabled] = useState(false)
  const [whitelistTokenBalance, setWhitelistTokenBalance] = useState(0)
  const [isEnded, setIsEnded] = useState(false)
  const [endDate, setEndDate] = useState()

  const [alertState, setAlertState] = useState({
    open: false,
    message: '',
    severity: undefined,
  })

  const wallet = useAnchorWallet()
  const [candyMachine, setCandyMachine] = useState()

  const { rpcUrl } = props

  const refreshCandyMachineState = () => {
    ;(async () => {
      if (!wallet) return

      const cndy = await getCandyMachineState(
        wallet,
        props.candyMachineId,
        props.connection,
      )

      setCandyMachine(cndy)
      setItemsAvailable(cndy.state.itemsAvailable)
      setItemsRemaining(cndy.state.itemsRemaining)
      setItemsRedeemed(cndy.state.itemsRedeemed)

      var divider = 1
      if (decimals) {
        divider = +('1' + new Array(decimals).join('0').slice() + '0')
      }

      // detect if using spl-token to mint
      if (cndy.state.tokenMint) {
        setPayWithSplToken(true)
        // Customize your SPL-TOKEN Label HERE
        // TODO: get spl-token metadata name
        setPriceLabel(splTokenName)
        setPrice(cndy.state.price.toNumber() / divider)
        setWhitelistPrice(cndy.state.price.toNumber() / divider)
      } else {
        setPrice(cndy.state.price.toNumber() / LAMPORTS_PER_SOL)
        setWhitelistPrice(cndy.state.price.toNumber() / LAMPORTS_PER_SOL)
      }

      // fetch whitelist token balance
      if (cndy.state.whitelistMintSettings) {
        setWhitelistEnabled(true)
        if (
          cndy.state.whitelistMintSettings.discountPrice !== null &&
          cndy.state.whitelistMintSettings.discountPrice !== cndy.state.price
        ) {
          if (cndy.state.tokenMint) {
            setWhitelistPrice(
              cndy.state.whitelistMintSettings.discountPrice?.toNumber() /
                divider,
            )
          } else {
            setWhitelistPrice(
              cndy.state.whitelistMintSettings.discountPrice?.toNumber() /
                LAMPORTS_PER_SOL,
            )
          }
        }
        let balance = 0
        try {
          const tokenBalance = await props.connection.getTokenAccountBalance(
            (
              await getAtaForMint(
                cndy.state.whitelistMintSettings.mint,
                wallet.publicKey,
              )
            )[0],
          )

          balance = tokenBalance?.value?.uiAmount || 0
        } catch (e) {
          console.error(e)
          balance = 0
        }
        setWhitelistTokenBalance(balance)
        setIsActive(!isEnded && balance > 0)
      } else {
        setWhitelistEnabled(false)
      }

      // end the mint when date is reached
      if (cndy?.state.endSettings?.endSettingType.date) {
        setEndDate(toDate(cndy.state.endSettings.number))
        if (
          cndy.state.endSettings.number.toNumber() <
          new Date().getTime() / 1000
        ) {
          setIsEnded(true)
          setIsActive(false)
        }
      }
      // end the mint when amount is reached
      if (cndy?.state.endSettings?.endSettingType.amount) {
        let limit = Math.min(
          cndy.state.endSettings.number.toNumber(),
          cndy.state.itemsAvailable,
        )
        setItemsAvailable(limit)
        if (cndy.state.itemsRedeemed < limit) {
          setItemsRemaining(limit - cndy.state.itemsRedeemed)
        } else {
          setItemsRemaining(0)
          cndy.state.isSoldOut = true
          setIsEnded(true)
        }
      } else {
        setItemsRemaining(cndy.state.itemsRemaining)
      }

      if (cndy.state.isSoldOut) {
        setIsActive(false)
      }
    })()
  }

  const renderGoLiveDateCounter = ({ days, hours, minutes, seconds }) => {
    return (
      <div>
        <Card elevation={1}>
          <h1>{days}</h1>
          <br />
          Days
        </Card>
        <Card elevation={1}>
          <h1>{hours}</h1>
          <br />
          Hours
        </Card>
        <Card elevation={1}>
          <h1>{minutes}</h1>
          <br />
          Mins
        </Card>
        <Card elevation={1}>
          <h1>{seconds}</h1>
          <br />
          Secs
        </Card>
      </div>
    )
  }

  const renderEndDateCounter = ({ days, hours, minutes }) => {
    let label = ''
    if (days > 0) {
      label += days + ' days '
    }
    if (hours > 0) {
      label += hours + ' hours '
    }
    label += minutes + 1 + ' minutes left to MINT.'
    return (
      <div>
        <h3>{label}</h3>
      </div>
    )
  }

  function displaySuccess(mintPublicKey) {
    let remaining = itemsRemaining - 1
    setItemsRemaining(remaining)
    setIsSoldOut(remaining === 0)
    if (whitelistTokenBalance && whitelistTokenBalance > 0) {
      let balance = whitelistTokenBalance - 1
      setWhitelistTokenBalance(balance)
      setIsActive(!isEnded && balance > 0)
    }
    setItemsRedeemed(itemsRedeemed + 1)
    const solFeesEstimation = 0.012 // approx
    if (!payWithSplToken && balance && balance > 0) {
      setBalance(
        balance -
          (whitelistEnabled ? whitelistPrice : price) -
          solFeesEstimation,
      )
    }
    setSolanaExplorerLink(
      cluster === 'devnet' || cluster === 'testnet'
        ? 'https://solscan.io/token/' + mintPublicKey + '?cluster=' + cluster
        : 'https://solscan.io/token/' + mintPublicKey,
    )
    throwConfetti()
  }

  function throwConfetti() {
    confetti({
      particleCount: 400,
      spread: 70,
      origin: { y: 0.6 },
    })
  }

  const onMint = async () => {
    try {
      setIsMinting(true)
      if (wallet && candyMachine?.program && wallet.publicKey) {
        const mint = anchor.web3.Keypair.generate()
        const mintTxId = (
          await mintOneToken(candyMachine, wallet.publicKey, mint)
        )[0]

        let status = { err: true }
        if (mintTxId) {
          status = await awaitTransactionSignatureConfirmation(
            mintTxId,
            props.txTimeout,
            props.connection,
            'singleGossip',
            true,
          )
        }

        if (!status?.err) {
          setAlertState({
            open: true,
            message: 'Congratulations! Mint succeeded!',
            severity: 'success',
          })

          // update front-end amounts
          displaySuccess(mint.publicKey)
        } else {
          setAlertState({
            open: true,
            message: 'Mint failed! Please try again!',
            severity: 'error',
          })
        }
      }
    } catch (error) {
      // TODO: blech:
      let message = error.msg || 'Minting failed! Please try again!'
      if (!error.msg) {
        if (!error.message) {
          message = 'Transaction Timeout! Please try again.'
        } else if (error.message.indexOf('0x138')) {
        } else if (error.message.indexOf('0x137')) {
          message = `SOLD OUT!`
        } else if (error.message.indexOf('0x135')) {
          message = `Insufficient funds to mint. Please fund your wallet.`
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`
        }
      }

      setAlertState({
        open: true,
        message,
        severity: 'error',
      })
    } finally {
      setIsMinting(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey)
        setBalance(balance / LAMPORTS_PER_SOL)
      }
    })()
  }, [wallet, props.connection])

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
    isEnded,
  ])

  return (
    <main>
      <MainContainer>
        <WalletContainer>
          <Wallet>
            {wallet ? (
              <WalletAmount>
                {(balance || 0).toLocaleString()} SOL
                <ConnectButton />
              </WalletAmount>
            ) : (
              <ConnectButton>Connect Wallet</ConnectButton>
            )}
          </Wallet>
        </WalletContainer>
        <ShimmerTitle>MINT IS LIVE !</ShimmerTitle>
        <br />

        <MintContainer>
          <NFT elevation={3}>
            <h2>My NFT</h2>
            <br />
            <div>
              <Price
                label={
                  isActive && whitelistEnabled && whitelistTokenBalance > 0
                    ? whitelistPrice + ' ' + priceLabel
                    : price + ' ' + priceLabel
                }
              />
              <Image src={gif} alt="Loading gif" />
            </div>
            <br />
            {wallet &&
              isActive &&
              whitelistEnabled &&
              whitelistTokenBalance > 0 && (
                <h3>
                  You own {whitelistTokenBalance} WL mint{' '}
                  {whitelistTokenBalance > 1 ? 'tokens' : 'token'}.
                </h3>
              )}
            {wallet && isActive && endDate && Date.now() < endDate.getTime() && (
              <Countdown
                date={toDate(candyMachine?.state?.endSettings?.number)}
                onMount={({ completed }) => completed && setIsEnded(true)}
                onComplete={() => {
                  setIsEnded(true)
                }}
                renderer={renderEndDateCounter}
              />
            )}
            {/* {wallet && isActive && (
              <h3>
                TOTAL MINTED : {itemsRedeemed} / {itemsAvailable}
              </h3>
            )}
            {wallet && isActive && (
              <BorderLinearProgress
                variant="determinate"
                className='border-linear-progress'
                value={100 - (itemsRemaining * 100) / itemsAvailable}
              />
            )} */}
            <br />
            <MintButtonContainer>
              {!isActive && !isEnded && candyMachine?.state.goLiveDate ? (
                <Countdown
                  date={toDate(candyMachine?.state.goLiveDate)}
                  onMount={({ completed }) =>
                    completed && setIsActive(!isEnded)
                  }
                  onComplete={() => {
                    setIsActive(!isEnded)
                  }}
                  renderer={renderGoLiveDateCounter}
                />
              ) : !wallet ? (
                <ConnectButton>Connect Wallet</ConnectButton>
              ) : candyMachine?.state.gatekeeper &&
                wallet.publicKey &&
                wallet.signTransaction ? (
                <GatewayProvider
                  wallet={{
                    publicKey:
                      wallet.publicKey || new PublicKey(CANDY_MACHINE_PROGRAM),
                    //@ts-ignore
                    signTransaction: wallet.signTransaction,
                  }}
                  // // Replace with following when added
                  // gatekeeperNetwork={candyMachine.state.gatekeeper_network}
                  gatekeeperNetwork={
                    candyMachine?.state?.gatekeeper?.gatekeeperNetwork
                  } // This is the ignite (captcha) network
                  /// Don't need this for mainnet
                  clusterUrl={rpcUrl}
                  options={{ autoShowModal: false }}
                >
                  <MintButton
                    candyMachine={candyMachine}
                    isMinting={isMinting}
                    isActive={isActive}
                    isEnded={isEnded}
                    isSoldOut={isSoldOut}
                    onMint={onMint}
                  />
                </GatewayProvider>
              ) : (
                <MintButton
                  candyMachine={candyMachine}
                  isMinting={isMinting}
                  isActive={isActive}
                  isEnded={isEnded}
                  isSoldOut={isSoldOut}
                  onMint={onMint}
                />
              )}
            </MintButtonContainer>
            <br />
            {wallet && isActive && solanaExplorerLink && (
              <SolExplorerLink href={solanaExplorerLink} target="_blank">
                View on Solscan
              </SolExplorerLink>
            )}
          </NFT>
        </MintContainer>
      </MainContainer>
      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </main>
  )
}

export default Home
